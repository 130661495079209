import React, { useState } from "react";
import AccordionUsage from "../../Components/Accordion/Accordion";
// import Img from "../../Assests/Images/Rectangle 31.png";
import Img from "../../Assests/Images/pexels-anna-nekrashevich-6802044.jpg";
import FadeInSection from "../../Utils/FadeInSection/FadeInSection ";
import { FaRegFilePdf } from "react-icons/fa";
import GetInTouchCard from "../../Components/GetInTouchCard/GetInTouchCard";
function Faq() {
  const AccordionData = [
    {
      title: "Why should I invest in your company?",
      detail:
        "Investing is a complex process. Our company has over 15 years of expertise in this field, during which we've developed tailored strategies to suit your investment objectives. Successful investing requires thorough research and timely analysis. We provide that with our dedicated information-sourcing mechanisms, ensuring you stay ahead in this dynamic financial investment landscape.",
    },
    {
      title: "What does your investment company specialize in?",
      detail:
        "We offer a broad range of investment services, including wealth management, portfolio diversification,financial planning, and retiremnt solutions. Our strategies are customized to help clients achieve their financial goals efficiently.",
    },
    {
      title: "Is there any guaranteed return on investment?",
      detail:
        "No, But we provide a formal agreement with every investor, outlining all details regarding the security of funds and expected return on investment (ROI), giving you peace of mind regarding your investment.",
    },
    {
      title: "What risks are associated with investing?",
      detail:
        "All investments involve risk, including the potential loss of principal. The level of risk varies based on the asset class (e.g., stocks vs. bonds) and market conditions. We aim to balance risk and reward based on your risk tolerance. To mitigate risk, we ensure the funds in which we invest are insured.",
    },
    {
      title: "How often will I receive updates on my investments?",
      detail:
        "You will receive regular statements, either monthly or quarterly, depending on your account type. Additionally, you can log in to our platform anytime to track and monitor your portfolio's performance.",
    },
    {
      title: "What are the modes and cycles of payment?",
      detail:
        "We accept payments through bank transfers (cheque and electronic payments). Our payment structure includes monthly and quarterly payouts of returns, ensuring transparency and convenience",
    },
    {
      title: "What is the minimum investment required to start?",
      detail:
        "Our minimum investment requirement varies depending on the type of investment product you choose. We offer options for a wide range of budgets, making it accessible for both new and seasoned investors. Contact us to discuss options that best suit your financial goals.",
    },
    {
      title: "How do you protect my investment?",
      detail:
        "We take several measures to safeguard your investment, including diversifying portfolios, investing in insured funds, and performing regular risk assessments. Additionally, all client funds are held in secure accounts with reputable financial institutions.",
    },
    {
      title: "Can I withdraw my funds at any time?",
      detail:
        "Yes, you can withdraw your funds based on the terms agreed upon for your specific investment plan. Some investments may have a fixed holding period to maximize returns, while others offer more flexibility. We’ll guide you on withdrawal options suitable for your needs.",
    },
    {
      title: "How does your company charge fees for its services?",
      detail:
        "Our fee structure is transparent and based on the services provided. For portfolio management, we typically charge a small percentage of the total assets under management. We also have clear terms for other services, which we outline in our client agreement.",
    },
    {
      title: "Can I reinvest my returns?",
      detail:
        "Yes, you can opt to reinvest your returns, allowing your investment to grow further through compounding. Reinvestment options are available for most of our products, and you can discuss the best reinvestment strategies with your advisor.",
    },
    {
      title: "What support can I expect from your team?",
      detail:
        "We have a dedicated team of financial advisors and support staff available to assist you with any questions or concerns. Our team provides ongoing advice, portfolio reviews, and timely updates to ensure your investment objectives are consistently met.",
    },
    {
      title: "What sets your investment company apart from others?",
      detail:
        "Our commitment to personalized service, extensive market research, and transparent communication sets us apart. With over 15 years of experience, we prioritize our clients' financial success and tailor strategies to meet individual goals effectively.",
    },
    {
      title: "What tax implications should I be aware of?",
      detail:
        "Investments often have tax implications, such as capital gains and dividends. While we provide general guidance on tax-efficient strategies, we recommend consulting a tax professional to ensure compliance with your specific tax obligations.",
    },
    {
      title: "Can I transfer my existing investments to your company?",
      detail:
        "Yes, we offer assistance with transferring existing investments to our management. Our team will guide you through the process, ensuring a smooth transition while maintaining your investment portfolio’s integrity and performance.",
    },
    {
      title: "What happens if market conditions change drastically?",
      detail:
        "We monitor the markets constantly and adjust strategies based on real-time data and market insights. In case of drastic changes, we will review and, if needed, rebalance your portfolio to align with your investment goals and risk tolerance.",
    },
    {
      title: "Are there any penalties for early withdrawal?",
      detail:
        "Some investment products may have early withdrawal fees, depending on the terms of the specific plan. We will clearly outline any potential penalties at the start so you can make informed decisions.",
    },
    {
      title: "How long do I need to wait to see returns on my investment?",
      detail:
        "The timeframe for returns varies based on the type of investment. Generally, investments in stocks may show returns in a shorter period, whereas real estate or fixed-income products may take longer. We provide estimates for each investment to help set expectations.",
    },
    {
      title: "Do you offer socially responsible investment options?",
      detail:
        "Yes, we understand the importance of aligning investments with personal values. We offer socially responsible and ESG (Environmental, Social, and Governance) investment options for clients who want their portfolios to reflect ethical and sustainable practices.",
    },
  ];
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panelIndex) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelIndex : false);
  };
  return (
    <div className="w-full flex items-center justify-center flex-col gap-[2rem]  pt-4">
      <FadeInSection>
        <div className="w-[90%] mobile:w-[90%] tablet:w-[90%] bg-darkGreen flex items-center justfiy-center flex-col rounded py-4 px-4 gap-4">
          <h1 className="text-white text-4xl font-semibold">
            Frequently Asked Questions
          </h1>
          <div className="w-full grid grid-cols-2 mobile:grid-cols-1 tablet:grid-col-1  items-start justify-start py-2 gap-4">
            <div className="flex items-center justify-center gap-1 flex-col mobile:order-last tablet:order-last">
              {AccordionData?.map((item, index) => (
                <AccordionUsage
                  title={item?.title}
                  detail={item?.detail}
                  index={index}
                  expanded={expanded === index}
                  handleChange={handleChange(index)}
                />
              ))}
            </div>
            <div className="flex items-center justify-center w-full h-full">
              <img src={Img} alt="img" className="w-[80%] " />
            </div>
          </div>
        </div>
      </FadeInSection>
      <div className="w-[90%] mobile:w-[90%] tablet:w-[90%]  flex items-start justfiy-start  px-4 gap-4">
        <FadeInSection>
          <span className="w-full  flex items-start justify-start  ">
            <a
              href="/Investment Brochure.pdf"
              download={"Investment_Brochure.pdf"}
            >
              <button className="flex items-center justify-center gap-1 button bg-green text-white">
                {" "}
                PDF Download <FaRegFilePdf />
              </button>
            </a>
          </span>
        </FadeInSection>
      </div>
      <div className="w-full flex items-center justify-center flex-col gap-[2rem] py-4 pt-[3rem]">
        <FadeInSection>
          <div className="w-[80%] mobile:w-[90%]">
            <GetInTouchCard />
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}

export default Faq;
