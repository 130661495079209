import React from "react";
import TemsCard from "../../Components/TemsCard/TemsCard";
import line from "../../Assests/Images/Group 65.png";
import SecondTeamCard from "../../Components/TemsCard/SecondTeamCard";
import FadeInSection from "../../Utils/FadeInSection/FadeInSection ";
import GetInTouchCard from "../../Components/GetInTouchCard/GetInTouchCard";
import fuzzelAhmad from "../../Assests/Images/Members/fuzzel Ahmad.png";
import IshaqAnsari from "../../Assests/Images/Members/Ishaq Ansari.png";
import MohdtalibAziz from "../../Assests/Images/Members/Mohd talib Aziz.png";
import NaseemKhan from "../../Assests/Images/Members/Naseem Khan.png";
import SaifahmadAdvisoryboardmember from "../../Assests/Images/Members/Saif ahmad  Advisory board member.png";
// import yogeshtiwari from "../../Assests/Images/Members/yogesh tiwari.png";
import ZiaUlIslam from "../../Assests/Images/Members/Zia Ul Islam.png";
import Mohdyasir from "../../Assests/Images/Members/Mohd yasir.png";
import shoaibkhan from "../../Assests/Images/Members/shoaib khan.png";
import yogeshtiwari from "../../Assests/Images/Members/yogesh tiwari.png";
function Teams() {
  const management = [
    {
      image: MohdtalibAziz,
      Name: "Mohd Talib Aziz",
      Designation: "Executive Director",
    },
    {
      image: ZiaUlIslam,
      Name: "Zia Ul Islam",
      Designation: "COO",
    },
    { image: Mohdyasir,
      Name: "Mohd Yasir",
      Designation: "CIO",
    },
  ];
  const additionalDirectors = [
    {
      image: IshaqAnsari,
      Name: "Ishaq Ansari",
      Designation: "Additional Director",
    },
    {
      Name: "Syed Akbar",
      Designation: "Additional Director",
    },
    {
      Name: "Rashad Mahmood",
      Designation: "Additional Director",
    },
    {
      Name: "Shariq Salmani",
      Designation: "Additional Director",
    },
   
  ];
  const advisoryBoard = [
    {
      image: SaifahmadAdvisoryboardmember,
      Name: "Saif Ahmad",
      Designation: "Advisory Board Member",
    },
    {
      image: yogeshtiwari,
      Name: "Yogesh Tiwari",
      Designation: "Jerome Bell",
      Designation: "Advisory Board Member",
    },
    {
      Name: "Saif Ansari",
      Designation: "Advisory Board Member",
    },
    {
      image: NaseemKhan,
      Name: "Naseem Khan",
      Designation: "Advisory Board Member",
    },
    {
      image: fuzzelAhmad,
      Name: "Fuzzel Ahmad",
      Designation: "Advisory Board Member",
    },
    {
      image: shoaibkhan,
      Name: "Shoeb Jameel",
      Designation: "Advisory Board Member",
    },
  ];
  return (
    <div className="w-full flex items-center justify-center flex-col gap-[2rem] mt-[1rem]  pt-4">
      <div className="w-[90%] mobile:w-[90%] tablet:w-[90%]  grid grid-cols-2 mobile:grid-cols-1 gap-4  px-4 gap-4">
        <h1 className=" text-4xl font-semibold">Our Special Teams</h1>
        <p className="text-footerText">
          Our team of experts brings together years of experience in financial
          consulting, venture capital, and stock market analysis. We work
          collaboratively with our clients to ensure their investments grow in a
          safe and sustainable manner.
        </p>
      </div>
      <div className="w-[90%] mobile:w-[90%] tablet:w-[90%]  flex items-start justify-start flex-col gap-4  px-4 gap-4">
        <h2 className=" text-2xl font-semibold">Management</h2>
        <div className="grid grid-cols-3 mobile:grid-cols-1 tablet:grid-cols-2 md:grid-cols-2 justify-between w-full gap-4 mb-2">
          {management?.map((item) => (
            <SecondTeamCard
              key={item?.Name}
              image={item?.image}
              Name={item?.Name}
              Desigation={item?.Designation}
            />
          ))}
  
        </div>
        <img src={line} alt="line" />
      </div>{" "}
      <div className="w-[90%] mobile:w-[90%] tablet:w-[90%]  flex items-start justify-start flex-col gap-4  px-4 gap-4">
        <h2 className=" text-2xl font-semibold">Additional Directors</h2>
        <div className="grid grid-cols-3 mobile:grid-cols-1 tablet:grid-cols-2 md:grid-cols-2 gap-4 justify-between w-full mb-2">
        {additionalDirectors?.map((item) => (
            <SecondTeamCard
              key={item?.Name}
              image={item?.image}
              Name={item?.Name}
              Desigation={item?.Designation}
            />
          ))}
       
        </div>
        <img src={line} alt="line" />
      </div>{" "}
      <div className="w-[90%] mobile:w-[90%] tablet:w-[90%]  flex items-start justify-start flex-col gap-4  px-4 gap-4">
        <h2 className=" text-2xl font-semibold">Advisory Board </h2>
        <div className="grid grid-cols-3 mobile:grid-cols-1 tablet:grid-cols-2 md:grid-cols-2 gap-4 justify-between w-full mb-2">
        {advisoryBoard?.map((item) => (
            <SecondTeamCard
              key={item?.Name}
              image={item?.image}
              Name={item?.Name}
              Desigation={item?.Designation}
            />
          ))}
        
        </div>
        <img src={line} alt="line" />
      </div>
      <div className="w-full flex items-center justify-center flex-col gap-[3rem] py-4 pt-[3rem]">
        <FadeInSection>
          <div className="w-[80%] mobile:w-[90%]">
            <GetInTouchCard />
          </div>
        </FadeInSection>
      </div>
    </div>
  );
}

export default Teams;
