import "./App.css";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import NavBar from "./Components/NavBar/NavBar";
import ScrollToTop from "./ScrollToTop";
import Footer from "./Components/Footer/Footer";
import { lazy, Suspense, useEffect, useState } from "react";
import Loader from "./Components/Loader/Loader";
import ErrorPage from "./Pages/ErrorPage/ErrorPage";
import Faq from "./Pages/FAQ/Faq";
import Teams from "./Pages/Teams/Teams";
const Home = lazy(() => import("./Pages/Home/Home"));
const AboutUs = lazy(() => import("./Pages/AboutUs/AboutUs"));
const OurService = lazy(() => import("./Pages/OurService/OurService"));
const Blog = lazy(() => import("./Pages/Blog/Blog"));
const SingleBlog = lazy(() => import("./Pages/Blog/SingleBlog"));
const ContactUs = lazy(() => import("./Pages/ContactUs/ContactUs"));

function App() {
  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
  }, []);
  return (
    <BrowserRouter>
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <Home />
            </Suspense>
          }
          path="/"
        />
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <AboutUs />
            </Suspense>
          }
          path="/aboutus"
        />
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <OurService />
            </Suspense>
          }
          path="/ourservice"
        />
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <Faq />
            </Suspense>
          }
          path="/faq"
        />
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <Teams />
            </Suspense>
          }
          path="/team"
        />
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <ContactUs />
            </Suspense>
          }
          path="/contactUs"
        />
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <Blog />
            </Suspense>
          }
          path="/blog"
        />
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <SingleBlog />
            </Suspense>
          }
          path="/blog/:singleblog"
        />{" "}
        <Route
          element={
            <Suspense fallback={<Loader />}>
              <ErrorPage />
            </Suspense>
          }
          path="*"
        />
      </Routes>
      <div className="flex -items-center justify-between w-full sticky right-[1rem] bottom-4">
        {scrolled && (
          <div className=" w-full relative rounded-md sticky left-[1rem] bottom-4 pr-4 flex items-start justify-start pl-[2.5%]">
            <div
              className="w-fit rounded-md "
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <Link to="https://wa.me/+918601299016" target="_blank">
                <dotlottie-player
                  src="Animation - 1730181263157.json"
                  background="transparent"
                  speed="1"
                  style={{ width: "4rem", height: "4rem" }}
                  direction="1"
                  playMode="normal"
                  loop
                  autoplay
                ></dotlottie-player>
              </Link>
            </div>
          </div>
        )}
        {scrolled && (
          <div className=" w-full relative rounded-md  pr-4 flex items-end justify-end">
            <div
              className="w-fit bg-white  border-2 rounded-md "
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <dotlottie-player
                src="Animation - 1729754390075.json"
                background="transparent"
                speed="1"
                style={{ width: "3rem", height: "3rem" }}
                direction="1"
                playMode="normal"
                loop
                autoplay
              ></dotlottie-player>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
